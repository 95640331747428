.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.header {
  background-color: #5c9d04;
}
.fix-dashboard-header {
  position: sticky;
  top: 0;
  bottom: 0;
  z-index: 9999;
}

.header h2 {
  color: white;
  text-align: center;
}

.bindResponse {
  margin-left: 300px;
}

.card {
  margin: 20px;
  padding: 20px;
  width: 90%;
  grid-template-rows: 20px 50px 1fr 50px;
  border-radius: 10px;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
  transition: all 0.2s;
}

.card-new {
  margin: 20px;
  padding: 20px;
  width: 20%;
  grid-template-rows: 20px 50px 1fr 50px;
  border-radius: 10px;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
  transition: all 0.2s;
}

.blue {
  background: radial-gradient(#1fe4f5, #3fbafe);
  color: white;
}

.purple {
  background: radial-gradient(#fbc1cc, #fa99b2);
  color: white;
}

.violet {
  background: radial-gradient(#76b2fe, #b69efe);
  color: white;
}

.green {
  background: radial-gradient(#60efbc, #58d5c9);
  color: white;
}

.button-active {
  background-color: grey;
}

.hint-star {
  color: gold;
  position: absolute;
  bottom: 0;
  right: 0;
}

.hint-star p {
  float: right;
  margin-right: 10px;
}
.tableResponse {
  margin-left: 300px;
  margin-right: 50px;
  margin-top: 50px;
}
.react-bs-table-csv-btn{
  margin-left: 30px;
  background-color: #282c34;
  color: white;
}

canvas {
  max-height: 400px;
}
.piechart {
  padding: 20px;
  border-radius: 25px;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
  margin-top: 10px;
  margin-bottom: 20px;
}

.avatar-image {
text-align: center;
}
img {
  max-width: 50%;
  max-height: 50%;
}

.btn-admin-login{
    background-color: #5c9d04;
    color:white;
    float: right;
}
.login-div{
  border: 2px solid #5C9D04; 
   border-radius: 25px;
}

.leaflet-container{
  width:900px; height:580px;
}